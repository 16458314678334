import React from 'react'
import { RealizationScrapTable } from 'workspace/RealizationScrapTable'
import { connect } from 'react-redux'
import { selectScrapIdsByHour, selectHour } from './selectors'
import moment from 'moment-timezone'

const NO_IDS = []

const Table = props => {
  if (props.scrapIds.length) {
    return (
      <RealizationScrapTable {...props} loading={false} skipActions />
    )
  } else {
    return null
  }
}

const mapStateToProps = state => {
  const key = +moment(selectHour(state))

  return {
    scrapIds: selectScrapIdsByHour(state)[key] || NO_IDS
  }
}

export default connect(mapStateToProps)(Table)
