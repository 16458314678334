import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import GRAReportHeader from './GRAReportHeader'
import GRAReportPlanSelect from './GRAReportPlanSelect'
import GRAReportGraph from 'reports/gra/GRAReportGraph'
import GRAReportHours from 'reports/gra/GRAReportHours'
import GRAReportScrapTable from './GRAReportScrapTable'

const ScrapParetoReport = ({ isLoaded }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        <GRAReportHeader />
        <GRAReportPlanSelect />
        <GRAReportGraph />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <GRAReportHours />
          <div style={{ flexGrow: 1 }}>
            <GRAReportScrapTable />
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

ScrapParetoReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(ScrapParetoReport)
