import React from 'react'
import ReportHeader from 'components/ReportHeader'
import { connect } from 'react-redux'
import Button from 'components/Button'
import Icon from 'components/Icon'
import PropTypes from 'prop-types'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { selectPlanId } from 'reports/gra/selectors'
import { FormattedMessage } from 'react-intl'

const Header = ({ planId, timePeriod }) => (
  <FormattedMessage id='reports.gra.name'>
    {text => (
      <ReportHeader name={text}>
        <div style={{ marginLeft: 10 }}>
          <a
            href={
              '/reports/gra/xlsx.xlsx?plan_id=' + planId +
              '&time_period=' + JSON.stringify(timePeriod)
            }
            format='xlsx'
            style={{ display: 'contents' }}
            target='_blank'
          >
            <Button color='success'>
              <Icon name='download' />
              &nbsp; XLS
            </Button>
          </a>
        </div>
      </ReportHeader>
    )}
  </FormattedMessage>
)

Header.propTypes = {
  planId: PropTypes.number.isRequired,
  timePeriod: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  planId: selectPlanId(state),
  timePeriod: selectNavigationRange(state)
})

export default connect(mapStateToProps)(Header)
