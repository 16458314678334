import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { selectReport, selectHour } from './selectors'
import { hourClicked } from './actions'
import { hourFormatter } from './GRAReportGraph'

const GRAReportHours = ({ hours, active, onHourClick }) => {
  if (!hours) {
    return null
  }

  return (
    <div style={{ width: 200, marginRight: 20 }}>
      <ul className='report-list'>
        {hours.map(hour => (
          <li
            key={hour}
            onClick={() => onHourClick(hour)}
            className={classnames('report-list__item', {
              'report-list__item--active': active === hour
            })}
          >
            {hourFormatter(hour)}
          </li>
        ))}
      </ul>
    </div>
  )
}

GRAReportHours.propTypes = {
  hours: PropTypes.array,
  active: PropTypes.string,
  onHourClick: PropTypes.func
}

const mapStateToProps = state => {
  const report = selectReport(state)

  return {
    hours: report ? report.map(e => e.hour) : null,
    active: selectHour(state)
  }
}

const mapDispatchToProps = dispatch => ({
  onHourClick: hour => dispatch(hourClicked(hour))
})

export default connect(mapStateToProps, mapDispatchToProps)(GRAReportHours)
