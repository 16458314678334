import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  RECEIVE_PLANS: 'RECEIVE_PLANS',
  CHANGE_PLAN: 'CHANGE_PLAN',
  RECEIVE_REPORT: 'RECEIVE_REPORT',
  HOUR_CLICKED: 'HOUR_CLICKED'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const receivePlans = createAction(Actions.RECEIVE_PLANS)
export const changePlan = createAction(Actions.CHANGE_PLAN)
export const receiveReport = createAction(Actions.RECEIVE_REPORT)
export const hourClicked = createAction(Actions.HOUR_CLICKED)
