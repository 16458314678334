import {
  all,
  fork,
  call,
  put,
  select,
  takeLatest,
  race,
  take,
  delay
} from 'redux-saga/effects'
import moment from 'moment-timezone'
import heartbeat from 'lib/saga/heartbeat'
import api from 'lib/api/client'
import {
  selectToken,
  selectTimezone,
  selectPlanId
} from 'reports/gra/selectors'
import Actions, {
  startInit,
  finishInit,
  receivePlans,
  receiveReport
} from 'reports/gra/actions'
import {
  SET_NAVIGATION,
  setNavigationDate,
  configureNavigation,
  setNavigationLocations
} from 'containers/Navigation/actions'
import {
  selectNavigationRange,
  selectNavigationLocation
} from 'containers/Navigation/selectors'
import {
  receiveProjects,
  receiveProducts,
  receiveScrapTypes,
  receiveScraps,
  receiveLocations
} from 'workspace/actions'
import { TYPE_WEEK } from 'containers/Navigation/const'

const AUTORELOAD_INTERVAL = 60 * 1000

const scrapDecorator = ({ createdAt, reportedAt, ...scrap }, timezone) => ({
  ...scrap,
  reportedAt: moment.tz(reportedAt, timezone),
  createdAt: moment.tz(createdAt, timezone)
})

function * init () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  yield put(startInit())

  yield put(configureNavigation(TYPE_WEEK))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  const [locations] = yield all([call(api.get, '/locations', { token })])

  yield all([put(setNavigationLocations(locations))])
  yield put(receiveLocations(locations))

  const [projects, products, scrapTypes] = yield all([
    call(api.get, '/projects', { token }),
    call(api.get, '/products', { token }),
    call(api.get, '/scrap_types', { token })
  ])

  yield all([
    put(receiveProjects(projects)),
    put(receiveProducts(products)),
    put(receiveScrapTypes(scrapTypes))
  ])

  yield put(finishInit())
}

function * plans () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const timeParams = yield select(selectNavigationRange, timezone)
  const location = yield select(selectNavigationLocation)
  const params = { ...timeParams, location_id: location.id }

  const plans = yield call(api.get, '/production_plans', { params, token })
  yield put(receivePlans(plans))
}

function * reports () {
  const planId = yield select(selectPlanId)

  if (!planId) {
    yield put(receiveReport(null))
    return
  }

  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const timeParams = yield select(selectNavigationRange, timezone)
  const params = {
    ...timeParams,
    productionPlanId: planId
  }

  const report = yield call(api.get, '/reports/gra', { params, token })
  yield put(receiveReport(report))
}

function * scrap () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const timeParams = yield select(selectNavigationRange, timezone)
  const location = yield select(selectNavigationLocation)
  const params = { ...timeParams, locationId: location.id }

  if (!location.id) {
    return
  }

  const scraps = yield call(api.get, '/scraps', { token, params })
  const decoratedScraps = scraps.map(e => scrapDecorator(e, timezone))
  yield put(
    receiveScraps({
      scraps: decoratedScraps,
      locationId: location.id,
      percentage: 0
    })
  )
}

function * reload () {
  let result

  while (true) {
    result = yield race({
      action: take(SET_NAVIGATION),
      timeout: delay(AUTORELOAD_INTERVAL)
    })

    if (result.timeout) {
      yield call(reports)
    }
  }
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Reports:GRA')
  }

  yield takeLatest(SET_NAVIGATION, plans)
  yield takeLatest(SET_NAVIGATION, scrap)
  yield takeLatest([Actions.RECEIVE_PLANS, Actions.CHANGE_PLAN], reports)
  yield call(init)

  yield fork(reload)
}
