import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import Actions from 'reports/gra/actions'
import navigation from 'containers/Navigation/reducer'
import {
  locations,
  projects,
  products,
  scrapTypes,
  scraps
} from 'workspace/reducers'

const NO_PLANS = []

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const plans = handleActions(
  {
    [Actions.RECEIVE_PLANS]: (state, { payload }) => payload
  },
  NO_PLANS
)

const planId = handleActions(
  {
    [Actions.CHANGE_PLAN]: (state, { payload }) => payload,
    [Actions.RECEIVE_PLANS]: (state, { payload }) =>
      payload[0] ? payload[0].id : null
  },
  null
)

const report = handleActions(
  {
    [Actions.RECEIVE_REPORT]: (state, { payload }) => payload
  },
  null
)

const hour = handleActions(
  {
    [Actions.HOUR_CLICKED]: (state, { payload }) => payload
  },
  null
)

export default combineReducers({
  config,
  init,
  report,
  plans,
  planId,
  hour,
  scraps,
  projects,
  products,
  scrapTypes,
  locations,
  navigation
})
