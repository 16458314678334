import React from 'react'
import PropTypes from 'prop-types'

const GRAReportGraphLabel = ({ x, y, stroke, payload, formatter, ...rest }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={8}
        textAnchor='start'
        fill='#666'
        transform='rotate(66)'
      >
        {formatter(payload.value)}
      </text>
    </g>
  )
}

GRAReportGraphLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.shape({
    value: PropTypes.string
  }),
  formatter: PropTypes.func
}

export default GRAReportGraphLabel
