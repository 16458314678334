import { createSelector } from 'reselect'
import { selectNavigationLocation } from 'containers/Navigation/selectors'
import moment from 'moment-timezone'

export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectPlans = state => state.plans
export const selectPlanId = state => state.planId
export const selectReport = state => state.report
export const selectHour = state => state.hour

export const selectScrapIdsByHour = createSelector(
  state => state.scraps,
  selectPlanId,
  selectNavigationLocation,
  (scraps, planId, location) => {
    if (!location.id) {
      return null
    }

    const result = {}
    scraps.idsByLocation[location.id].forEach(id => {
      const scrap = scraps.allById[id]
      if (scrap.productionPlanId !== planId) {
        return
      }

      const key = +moment(scrap.reportedAt).startOf('hour')

      if (!result[key]) {
        result[key] = []
      }
      result[key].push(id)
    })

    return result
  }
)
