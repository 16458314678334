import React from 'react'
import PropTypes from 'prop-types'
import AssociationSelect from 'components/AssociationSelect'
import { connect } from 'react-redux'
import { selectPlans, selectPlanId } from 'reports/gra/selectors'
import { changePlan } from 'reports/gra/actions'

const labelMapper = record => record.productionCallNumber

const GRAReportPlanSelect = ({ plans, onChange, value }) => {
  return (
    <div className='report-section'>
      <AssociationSelect
        options={plans}
        labelMapper={labelMapper}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

GRAReportPlanSelect.propTypes = {
  plans: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.number
}

const mapStateToProps = state => ({
  plans: selectPlans(state),
  value: selectPlanId(state)
})

const mapDispatchToProps = dispatch => ({
  onChange: id => dispatch(changePlan(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(GRAReportPlanSelect)
