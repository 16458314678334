import React from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  ReferenceLine,
  LabelList,
  Tooltip
} from 'recharts'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectReport } from 'reports/gra/selectors'
import { FormattedMessage } from 'react-intl'
import GRAReportGraphLabel from './GRAReportGraphLabel'
import Unit from 'components/Unit'
import Icon from 'components/Icon'
import moment from 'moment-timezone'

export const hourFormatter = hour => {
  const momentHour = moment(hour)

  return `${momentHour.format('HH:mm')} - ${momentHour
    .add(59, 'minutes')
    .format('HH:mm - DD.MM')}`
}

const GRATooltip = ({ payload }) => {
  if (!payload || !payload[0]) {
    return null
  }

  const { hour, plannedScrapQuantity, realScrapLength } = payload[0].payload

  return (
    <div className='report-graph-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>{hourFormatter(hour)}</h4>
      </div>

      <div className='report-graph-tooltip__footer'>
        <div style={{ display: 'flex' }}>
          <div className='recharts-custom-tooltip__label'>
            <FormattedMessage id='reports.gra.plannedScrap' />
            &nbsp;
          </div>
          <div className='recharts-custom-tooltip__value'>
            <Unit value={plannedScrapQuantity.toFixed(2)} unit='meter' />
          </div>
        </div>
        <br />
        <div style={{ display: 'flex' }}>
          <div className='recharts-custom-tooltip__label'>
            <FormattedMessage id='reports.gra.realScrap' />
            &nbsp;
          </div>
          <div className='recharts-custom-tooltip__value'>
            <Unit value={realScrapLength.toFixed(2)} unit='meter' />
          </div>
        </div>
      </div>
    </div>
  )
}

GRATooltip.propTypes = {
  payload: PropTypes.array
}

const valueFormatter = value => value.toFixed(2)

const getYAxisDataKey = data => {
  const maxPlanned = Math.max(...data.map(e => e.plannedScrapQuantity))
  const maxReal = Math.max(...data.map(e => e.realScrapLength))

  return maxPlanned > maxReal ? 'plannedScrapQuantity' : 'realScrapLength'
}

const GRAReportGraph = ({ data }) => {
  if (!data) {
    return (
      <div className='report-section report-section--info'>
        <div
          style={{
            backgroundColor: '#3498db',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 40,
            height: 40,
            marginRight: 10,
            color: '#ffffff',
            fontSize: 25
          }}
        >
          <Icon name='info' />
        </div>
        &nbsp;
        <FormattedMessage id='workspace.form.noInfo' />
      </div>
    )
  }

  return (
    <div
      style={{ width: '100%', height: 500, marginTop: 18, marginBottom: 18 }}
    >
      <ResponsiveContainer>
        <ComposedChart
          width={600}
          height={400}
          data={data}
          margin={{ top: 20, right: 20, bottom: 120, left: 20 }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <ReferenceLine y={0} stroke='#000' yAxisId='left' />
          <XAxis
            dataKey='hour'
            interval={0}
            tick={<GRAReportGraphLabel formatter={hourFormatter} />}
          />
          <YAxis
            yAxisId='left'
            orientation='left'
            dataKey={getYAxisDataKey(data)}
          />
          <Bar
            yAxisId='left'
            dataKey='plannedScrapQuantity'
            barSize={40}
            fill='#2F81B7'
          >
            <LabelList position='top' formatter={valueFormatter} />
          </Bar>
          <Bar yAxisId='left' dataKey='realScrapLength' barSize={40}>
            {data.map(entry => (
              <Cell
                key={`cell-${+entry.hour}`}
                fill={
                  entry.plannedScrapQuantity >= entry.realScrapLength
                    ? '#30AD64'
                    : '#BF3A31'
                }
              />
            ))}
            <LabelList position='top' formatter={valueFormatter} />
          </Bar>
          <Tooltip content={GRATooltip} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

GRAReportGraph.propTypes = {
  data: PropTypes.array
}

const mapStateToProps = state => ({
  data: selectReport(state)
})

export default connect(mapStateToProps)(GRAReportGraph)
